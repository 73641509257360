import * as React from 'react'
import Layout from 'components/Layout'
import Seo from '../components/Seo'

const ValuesPage = () => {
  return (
    <Layout>
      <div className="pb-15 inner-container privacy-policy">
        <h1>Xanadu Values</h1>
        <p>
          Xanadu has eight values that drive our culture, habits, decision
          making and ways of working. They are deeply embedded in everything we
          do to achieve our mission: to build quantum computers that are useful
          and available to people everywhere.
        </p>

        <h2>Enjoying our work</h2>
        <ul>
          <li className="mb-2">
            Waking up each day and loving what we do. It doesn’t mean every day
            will be like that but more often than not we wouldn’t want to do
            anything else.
          </li>
          <li className="mb-2">
            Have <strong>fun</strong> and don’t take ourselves too seriously.
            Also work on finding a <strong>balance</strong> where we can switch
            off our minds from work when needed.
          </li>
          <li className="mb-2">
            If money was not an issue, would we still be happy working here?
            Things like <strong>media attention</strong> and{' '}
            <strong>recognition</strong> are always fleeting and a{' '}
            <strong>poor measure of success</strong>. Loving what we do goes
            deeper and lasts much longer.
          </li>
          <li className="mb-2">
            Another important consequence of loving what we do is being more{' '}
            <strong>immune to failure</strong>. What we are doing is something
            extremely hard, the classic moon shot. Because it is so hard to do,
            there is a chance of failure and that means there will be ups and
            downs. Having a foundation not in the outcome but in working on what
            we love, will be key.
          </li>
        </ul>
        <p className="pt-5 italic">
          “The ultimate dream in life is to be able to do what you love and
          learn something from it.” — Jennifer Love Hewitt
        </p>

        <h2>Learning every day</h2>
        <ul>
          <li className="mb-2">
            It’s important that we are <strong>learning</strong> every day and
            therefore are <strong>improving</strong> and{' '}
            <strong>getting better</strong> every day.
          </li>
          <li className="mb-2">
            Thinking back at the end of the day or week and asking if we have
            learnt something can be very meaningful.
          </li>
          <li className="mb-2">
            The motivation to want to learn everyday usually comes from an{' '}
            <strong>intense curiosity</strong> to understand the world around
            us.
          </li>
          <li className="mb-2">
            Having the mindset of being curious and wanting to learn allows
            people to be aligned with the mission in a{' '}
            <strong>cooperative manner</strong>. This can lead to a feeling of
            cooperation rather than trying to outcompete each other.
          </li>
        </ul>
        <p className="pt-5 italic">“Never stop learning.” — Richard Feynman</p>

        <h2>Attention to detail</h2>
        <ul>
          <li className="mb-2">
            Attention to <strong>detail</strong> will be key to our success.
            It’s perhaps impossible to find someone who achieved something great
            who didn&apos;t have a near extreme attention to detail.
          </li>
          <li className="mb-2">
            Attention to detail shows that we take <strong>pride</strong> and{' '}
            <strong>care</strong> in our work.
          </li>
          <li className="mb-2">
            No detail, for the most part, should be too small. In building
            something as difficult as a quantum computer, this{' '}
            <strong>healthy obsession</strong> to details will bring us a long
            way to achieving our goals.
          </li>
          <li className="mb-2">
            There needs to be a careful balance between moving quickly and
            unnecessary perfectionism. It is important to be mindful of that
            boundary, and include it in your assessment of when a project is
            complete.
          </li>
        </ul>
        <p className="pt-5 italic">
          “To create something exceptional, your mindset must be relentlessly
          focused on the smallest detail.” — Giorgio Armani
        </p>

        <h2>Commitment to excellence</h2>
        <ul>
          <li className="mb-2">
            Have a commitment to excellence, as best we can. There’s a real{' '}
            <strong>art</strong> and <strong>joy</strong> in{' '}
            <strong>mastering</strong> something and being hardcore about it.
          </li>
          <li className="mb-2">
            The concept of striving for excellence is strongly correlated with
            the concepts of taking <strong>care</strong> and{' '}
            <strong>pride</strong> in our work. A complete lack of excellence
            will most surely lead to failure.
          </li>
          <li className="mb-2">
            Another aspect that goes hand-in-hand with excellence is the{' '}
            <strong>desire</strong> to strive for something truly great backed
            by a <strong>healthy obsession</strong>. In order to achieve such a
            goal, <strong>persistence</strong> and going the{' '}
            <strong>extra mile</strong> are two key elements.
          </li>
        </ul>
        <p className="pt-5 italic">
          “Excellence isn’t a one-week or one-year ideal. It’s a constant.” —
          Michael Jordan
        </p>

        <h2>Sense of urgency</h2>
        <ul>
          <li className="mb-2">
            A <strong>sense of urgency</strong> and <strong>execution</strong>{' '}
            is what will successfully keep pushing us forward.
          </li>
          <li className="mb-2">
            We have a bias towards <strong>action</strong>.
          </li>
          <li className="mb-2">
            Execution is not necessarily related to results. It&apos;s more{' '}
            <strong>outcome independence</strong>, i.e., we don’t worry too much
            about the outcome of achieving a goal. But rather you lay the right
            foundation to achieve that goal and let the chips fall wherever they
            may.
          </li>
          <li className="mb-2">
            If we put all the hard work and execution into something and don’t
            achieve the desired outcome, then that’s okay because what more
            could we have done?
          </li>
        </ul>
        <p className="pt-5 italic">“Make haste slowly.” — Anonymous</p>

        <h2>Emotional intelligence</h2>
        <ul>
          <li className="mb-2">
            <strong>Emotional intelligence</strong> is key to building a long
            lasting company with healthy relationships, contented people and
            little turnover. Emotions are a fundamental human characteristic but
            need not dictate how we <strong>react</strong>, make{' '}
            <strong>decisions</strong> and <strong>communicate</strong> with
            people.
          </li>
          <li className="mb-2">
            By using and developing emotional intelligence, both in the
            individual and as a team, we&apos;re better equipped to better
            interpret our emotional responses and reactions to make{' '}
            <strong>better decisions</strong>, have stronger{' '}
            <strong>team connections</strong>, and{' '}
            <strong>healthier collaborations</strong>.
          </li>
          <li className="mb-2">
            It is important that we work towards improving ourselves and at the
            same time don’t be too hard on ourselves (or others) when we do
            fail.
          </li>
          <li className="mb-2">
            The more in tuned we become with our emotions the more we can{' '}
            <strong>respond better</strong> and{' '}
            <strong>react constructively</strong>.
          </li>
        </ul>
        <p className="pt-5 italic">
          “You will continue to suffer if you have an emotional reaction to
          everything that is said to you. True power is sitting back and
          observing things with logic. True power is restraint. If words control
          you that means everyone else can control you. Breathe and allow things
          to pass.” — Warren Buffet
        </p>

        <h2>Effective communication</h2>
        <ul>
          <li className="mb-2">
            A culture of <strong>listening</strong> more than speaking. Try to
            reduce the times we interrupt — this is especially important when
            talking to customers.
          </li>
          <li className="mb-2">
            Work on not letting your ego get in the way and share credit.
            Phrasing things as “ours” rather than “mine” or “I”. Also it’s okay
            to say “I don’t know” or “I’m not sure”. Create a culture of asking
            people to do things rather than telling them to do things.
          </li>
          <li className="mb-2">
            <strong>Transparency</strong> and <strong>openness</strong>: if
            there are issues they are brought up immediately and openly. Note
            that there will be times when you cannot be fully transparent (e.g.,
            salaries, employee’s health, etc.).
          </li>
          <li className="mb-2">
            Listen to <strong>rational arguments</strong>, evaluate them
            thoughtfully and make measured changes as a result, even when the
            conclusions are contrary to our existing opinion.
          </li>
          <li className="mb-2">
            Finally, it is extremely important that we have the{' '}
            <strong>freedom</strong> to give our opinions and to say what we
            think.
          </li>
        </ul>
        <p className="pt-5 italic">
          “You catch more flies with honey than vinegar.” — Benjamin Franklin
        </p>

        <h2>Impermanence</h2>
        <ul>
          <li className="mb-2">
            <strong>Impermanence</strong> means that{' '}
            <strong>nothing lasts forever</strong>. Depending on our perspective
            this may seem quite negative. But actually it is neither good nor
            bad — really just a fact of life.
          </li>
          <li className="mb-2">
            Impermanence allows us to not be complacent. Someone once said that
            a successful company should always be trying to destroy itself.
            Because this is what your competitors are trying to do.
          </li>
          <li className="mb-2">
            This should lead to <strong>constant innovation</strong> and the
            ability to{' '}
            <strong>take measured risks and to fail sometimes</strong>.
          </li>
          <li className="mb-2">
            Impermanence is perhaps at the heart of this because you realize
            that good times never last and thankfully, neither do bad times.
          </li>
        </ul>
        <p className="pt-5 italic">
          “It is not impermanence that makes us suffer. What makes us suffer is
          wanting things to be permanent when they are not.” — Nhat Hanh
        </p>
      </div>
    </Layout>
  )
}

export default ValuesPage

export const Head = () => {
  return (
    <Seo
      title="Xanadu Values"
      description="Xanadu has eight values that drive our culture, habits, decision making and ways of working. 
  They are deeply embedded in everything we do to achieve our mission: to build quantum computers 
  that are useful and available to people everywhere."
    />
  )
}
